// GSAP
// スクロールアニメーション
let fadeupEffect = gsap.utils.toArray(".fadeInUp");
fadeupEffect.forEach((target) => {
  gsap.fromTo(
    target, 
    {
      y: 120, 
      autoAlpha: 0,
    },
    {
      y: 0,
      autoAlpha: 1,
      duration: 1.5,
      ease: "power4.out",
      scrollTrigger: {
        trigger: target,
        start: "top 70%",
        end: "bottom 10%",
      },
    }
  );
});

// 慣性スクロール（Lenis)
const lenis = new Lenis({
  lerp: 0.4,
  duration: 1,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);
